import { SegmentsRepository } from "../../../repository/api/segment/segment";
import { actionCreators } from "../../../state";

export class SegmentUseCases {
    private api: SegmentsRepository;
    private stateActions: typeof actionCreators;

    constructor(api: SegmentsRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public SegmentsListWithCountOfLeadsByDate = async (date: Date, projectName: string, offset: number, limit: number) => {
        this.stateActions.SendSegmentListRequest()
        let response = await this.api.SegmentsListWithCountOfLeadsByDate(date, projectName, offset, limit)
        if (response instanceof Error) {
            this.stateActions.SegmentListError(response.message)
            return
        } else {
            this.stateActions.SegmentListSuccess(response.segments, response.count)
        }
    }

    public CreateSegment = async (projectName: string, callback: () => void) => {
        this.stateActions.SegmentCreateRequest()
        let response = await this.api.CreateSegment(projectName)
        if (response instanceof Error) {
            this.stateActions.SegmentCreateError(response.message)
            return
        } else {
            this.stateActions.SegmentCreateSuccess(response)
            callback()
        }
    }

    public CopySegment = async (id: UniqueId, segmentName: string, callback: () => void) => {
        this.stateActions.SegmentCreateRequest()
        let response = await this.api.CopySegment(id, segmentName)
        if (response instanceof Error) {
            this.stateActions.SegmentCreateError(response.message)
            return
        } else {
            this.stateActions.SegmentCreateSuccess(response)
            callback()
        }
    }

    public UpdateSegmentIntegration = async (id: string, leadType: string, pipelineId: number, statusId: number, cityId: number, entityId: number, tag: string, utmSource: string, perevodKc: boolean, callback: () => void) => {
        this.stateActions.SegmentCreateRequest()
        let response = await this.api.UpdateSegmentIntegration(id, leadType, pipelineId, statusId, cityId, entityId, tag, utmSource, perevodKc)
        if (response instanceof Error) {
            this.stateActions.SegmentCreateError(response.message)
            return
        } else {
            this.stateActions.SegmentCreateSuccess(response)
            callback()
        }
    }


    public DeleteSegment = async (id: UniqueId) => {
        this.stateActions.SegmentDeleteRequest()
        let response = await this.api.DeleteSegment(id)
        if (response instanceof Error) {
            this.stateActions.SegmentDeleteError(response.message)
            return
        } else {
            this.stateActions.SegmentDeleteSuccess(id)
        }
    }

    public ReadSegmentById = async (id: UniqueId) => {
        let response = await this.api.ReadSegmentById(id)
        return response
    }

    public ToggleSegmentIntegrationActivity = async (id: UniqueId, enabled: boolean) => {
        let response = await this.api.ToggleSegmentIntegrationActivity(id, enabled)
        return response
    }

    public ReadSegmentDomainsList = async (segmentId: UniqueId) => {
        let response = await this.api.SegmentDomainsList(segmentId)
        return response
    }

    public CreateDomainForSegment = async (segmentId: UniqueId, domain: string) => {
        let response = await this.api.CreateDomainForSegment(segmentId, domain)
        return response
    }

    public DeleteDomainOfSegment = async (domainId: UniqueId) => {
        let response = await this.api.DeleteDomainSegment(domainId)
        return response
    }

    public ReadSegmentPhonesList = async (segmentId: UniqueId) => {
        let response = await this.api.SegmentPhonesList(segmentId)
        return response
    }

    public CreatePhoneForSegment = async (segmentId: UniqueId, domain: string) => {
        let response = await this.api.CreatePhoneForSegment(segmentId, domain)
        return response
    }

    public DeletePhoneOfSegment = async (domainId: UniqueId) => {
        let response = await this.api.DeletePhoneSegment(domainId)
        return response
    }

    public ReadReport = async (month: number, year: number) => {
        let response = await this.api.ReadReport(month, year)
        return response
    }
}
