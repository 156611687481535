import { useContext, useEffect, useState } from "react"
import { UseCasesContext } from "../../../../context/useCases"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import { Link, useParams } from "react-router-dom"
import { TableRow } from "./tableRow"
import { Menu } from "../../../components/menu"
import Paginator from "../../../components/paginator/paginator"
import { Download } from "tabler-icons-react"

export const SegmentlList = () => {
    let { id } = useParams();
    const [page, setPage] = useState(0)
    const [countOnPage, setCountOnPage] = useState(50)
    const [notDefinedLines, setNotDefinedLines] = useState<string[]>([])

    const [form, setForm] = useState<{
        offset: number,
        limit: number,
        date: Date,
        projectName: string,
    }>({
        offset: 0,
        limit: 50,
        date: new Date(),
        projectName: "",
    })


    let useCases = useContext(UseCasesContext)

    const segments = useTypedSelector(({ segement }) => {
        return segement
    })

    const dateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let append = "T23:59:59Z"
        if (e.target.name === "dateStart") {
            append = "T00:00:00Z"
        }

        setForm({
            ...form,
            [e.target.name]: new Date(e.target.value)
        })
    }

    const downloadLines = async (lines: string[]) => {
        let csvContent = "data:text/csv;charset=utf-8,"

            csvContent += lines!.join("\n")
            var encodedUri = encodeURI(csvContent);

            var link = document.createElement('a');
            link.href = encodedUri;
            link.target = "_blank"
            link.download = "phones_and_sites.csv";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
    }

    const readNotDefinedLines = async () => {
        let response = await useCases?.leadsUseCase.ReadNotDefinedLines(form.date)
        if (response instanceof Error) {
            setNotDefinedLines([])
        } else {
            setNotDefinedLines(response!)
        }
    }

    const formatDate = (day: Date) => {
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return yyyy + '-' + mm + '-' + dd;
    }

    const downLoadAll = async () => {
        console.log(form.date.toString())
        let response = await useCases?.leadsUseCase.DownLoadDateGet(formatDate(form.date))
        if (response instanceof Error) {

        } else {
            if (response) {
                let csvContent = "data:text/csv;charset=utf-8,"
                csvContent += response
                const encodedUri = encodeURI(csvContent);
                const link = document.createElement('a');
                link.href = encodedUri;
                link.download = `export-from${formatDate(form.date)}.csv`;
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        }
    }

    useEffect(() => {

        setForm({
            ...form,
            offset: countOnPage * (page),
            limit: countOnPage,
        })
    }, [page, countOnPage])


    useEffect(() => {
        useCases?.segmentUseCase.SegmentsListWithCountOfLeadsByDate(form.date, form.projectName, form.offset, form.limit)
        readNotDefinedLines()
    }, [form])

    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-xl-5 col-md-4">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Список сегментов /</span> Сегменты</h4>
                        </div>

                        {
                        /*<div>
                            <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap me-1">
                                <div className="dt-buttons py-3 mb-4">
                                    <a href="" onClick={(e)=>{e.preventDefault(); downloadTz()}} className="dt-button add-new btn btn-primary mb-3 mb-md-0" ><span>Скачать ТЗ</span></a>
                                </div>
                            </div>
                        </div>*/
                        }

                        <div className="card mb-4">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 col-sm-6 col-lg-4 justify-content-end">
                                        <label className="form-label">Дата с:</label>
                                        <input type="date" value={form?.date.toISOString().split('T')[0]} name="date" onChange={dateChange} className="form-control dt-input dt-full-name" />
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 justify-content-end">
                                        <label className="form-label">Название проекта:</label>
                                        <input type="text" value={form?.projectName} name="name" onChange={(e) => { setForm({ ...form, projectName: e.target.value }) }} className="form-control dt-input dt-full-name" />
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap me-1">
                                <div className="dt-buttons py-3 mb-4 p-3">
                                    <button className="btn btn-primary mb-3 mb-md-0" onClick={downLoadAll}>Скачать все</button>
                                </div>

                                <div className="dt-buttons py-3 mb-4">
                                    <Link to={"/segment/add/"} className="dt-button add-new btn btn-primary mb-3 mb-md-0" ><span>Добавить сегмент</span></Link>
                                </div>
                            </div>
                        </div>

                        {notDefinedLines.length > 0 ? <div style={{ marginTop: 10, display:"flex", justifyContent: "space-between" }} className="alert alert-danger mb-5"  role="alert">
                            <span>Нераспределенных лидов за дату: {notDefinedLines.length}</span>
                            <button className="btn btn-primary" onClick={()=>{downloadLines(notDefinedLines)}}><Download size={15} style={{padding: 0}} /></button>
                        </div> : <></>
                        }
                                        
                        <div className="" style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                            <Paginator allCount={segments?.count!} page={page} setPage={(page: number) => setPage(page)} countOnPage={countOnPage} setCountOnPage={(count: number) => setCountOnPage(count)} />
                        </div>
                        <div className="card" style={{ background: "#fff", }}>
                            <div className="table-responsive text-nowrap col-xl-12">
                                <table className="table mt-1" style={{marginBottom: segments?.segments?.length == 1? "100px": 0}}>
                                    <thead>
                                        <tr>
                                            <th>Название проекта</th>
                                            <th>Интеграция</th>
                                            <th>Кол-во лидов</th>
                                            <th>Последняя обработка</th>
                                            <th>Скачать</th>
                                            <th>Дата создания</th>
                                            <th>Действия</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table table-hover">
                                        {
                                            segments?.segments && segments.segments.map((segment, index) => {
                                                return <TableRow key={segment.Name() + index} date={form.date} segment={segment} last={(segments?.segments!.length - 1 == index) && segments.segments?.length !== 1 ? true : false} />
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

}
