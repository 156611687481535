/* tslint:disable */
/* eslint-disable */
/**
 * hr-report backend
 * hr-report backend
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kanya384@mail.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AuthSignInRequest
 */
export interface AuthSignInRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthSignInRequest
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthSignInRequest
     */
    'pass'?: string;
}
/**
 * 
 * @export
 * @interface AuthSignInResponse
 */
export interface AuthSignInResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthSignInResponse
     */
    'refreshToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthSignInResponse
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DomainLeadType = {
    Default: 'default',
    Jk: 'jk',
    Avto: 'avto',
    Bank: 'bank'
} as const;

export type DomainLeadType = typeof DomainLeadType[keyof typeof DomainLeadType];


/**
 * 
 * @export
 * @interface DomainSegmentWithLeadsCount
 */
export interface DomainSegmentWithLeadsCount {
    /**
     * 
     * @type {string}
     * @memberof DomainSegmentWithLeadsCount
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomainSegmentWithLeadsCount
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DomainSegmentWithLeadsCount
     */
    'is_integration_active'?: boolean;
    /**
     * 
     * @type {object}
     * @memberof DomainSegmentWithLeadsCount
     */
    'last_processed_lead_date'?: object;
    /**
     * 
     * @type {number}
     * @memberof DomainSegmentWithLeadsCount
     */
    'leads_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof DomainSegmentWithLeadsCount
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface LeadgenAmoStatusListResponse
 */
export interface LeadgenAmoStatusListResponse {
    /**
     * 
     * @type {Array<LeadgenStatus>}
     * @memberof LeadgenAmoStatusListResponse
     */
    'list': Array<LeadgenStatus>;
}
/**
 * 
 * @export
 * @interface LeadgenCity
 */
export interface LeadgenCity {
    /**
     * 
     * @type {number}
     * @memberof LeadgenCity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof LeadgenCity
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface LeadgenCityListResponse
 */
export interface LeadgenCityListResponse {
    /**
     * 
     * @type {Array<LeadgenCity>}
     * @memberof LeadgenCityListResponse
     */
    'list': Array<LeadgenCity>;
}
/**
 * 
 * @export
 * @interface LeadgenStatus
 */
export interface LeadgenStatus {
    /**
     * 
     * @type {number}
     * @memberof LeadgenStatus
     */
    'pipelineId': number;
    /**
     * 
     * @type {string}
     * @memberof LeadgenStatus
     */
    'pipelineName': string;
    /**
     * 
     * @type {number}
     * @memberof LeadgenStatus
     */
    'statusId': number;
    /**
     * 
     * @type {string}
     * @memberof LeadgenStatus
     */
    'statusName': string;
}
/**
 * 
 * @export
 * @interface RestErrorResponse
 */
export interface RestErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof RestErrorResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface SegmentCopySegmentRequest
 */
export interface SegmentCopySegmentRequest {
    /**
     * 
     * @type {string}
     * @memberof SegmentCopySegmentRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SegmentCopySegmentRequest
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface SegmentCreateDomainRequest
 */
export interface SegmentCreateDomainRequest {
    /**
     * 
     * @type {string}
     * @memberof SegmentCreateDomainRequest
     */
    'domain'?: string;
}
/**
 * 
 * @export
 * @interface SegmentCreatePhoneRequest
 */
export interface SegmentCreatePhoneRequest {
    /**
     * 
     * @type {string}
     * @memberof SegmentCreatePhoneRequest
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface SegmentCreateSegemntRequest
 */
export interface SegmentCreateSegemntRequest {
    /**
     * 
     * @type {string}
     * @memberof SegmentCreateSegemntRequest
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface SegmentReadMonthReport
 */
export interface SegmentReadMonthReport {
    /**
     * 
     * @type {number}
     * @memberof SegmentReadMonthReport
     */
    'month'?: number;
    /**
     * 
     * @type {number}
     * @memberof SegmentReadMonthReport
     */
    'year'?: number;
}
/**
 * 
 * @export
 * @interface SegmentSegmentDomainResponse
 */
export interface SegmentSegmentDomainResponse {
    /**
     * 
     * @type {string}
     * @memberof SegmentSegmentDomainResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof SegmentSegmentDomainResponse
     */
    'domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof SegmentSegmentDomainResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SegmentSegmentDomainResponse
     */
    'segment_id'?: string;
}
/**
 * 
 * @export
 * @interface SegmentSegmentIntegrationParams
 */
export interface SegmentSegmentIntegrationParams {
    /**
     * 
     * @type {number}
     * @memberof SegmentSegmentIntegrationParams
     */
    'city_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof SegmentSegmentIntegrationParams
     */
    'entity_id'?: number;
    /**
     * 
     * @type {DomainLeadType}
     * @memberof SegmentSegmentIntegrationParams
     */
    'lead_type'?: DomainLeadType;
    /**
     * 
     * @type {boolean}
     * @memberof SegmentSegmentIntegrationParams
     */
    'perevod_kc'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SegmentSegmentIntegrationParams
     */
    'pipeline_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof SegmentSegmentIntegrationParams
     */
    'status_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SegmentSegmentIntegrationParams
     */
    'tag'?: string;
    /**
     * 
     * @type {string}
     * @memberof SegmentSegmentIntegrationParams
     */
    'utm_source'?: string;
}
/**
 * 
 * @export
 * @interface SegmentSegmentPhoneResponse
 */
export interface SegmentSegmentPhoneResponse {
    /**
     * 
     * @type {string}
     * @memberof SegmentSegmentPhoneResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof SegmentSegmentPhoneResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SegmentSegmentPhoneResponse
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof SegmentSegmentPhoneResponse
     */
    'segment_id'?: string;
}
/**
 * 
 * @export
 * @interface SegmentSegmentResponse
 */
export interface SegmentSegmentResponse {
    /**
     * 
     * @type {number}
     * @memberof SegmentSegmentResponse
     */
    'city_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SegmentSegmentResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof SegmentSegmentResponse
     */
    'entity_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SegmentSegmentResponse
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SegmentSegmentResponse
     */
    'is_integration_active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SegmentSegmentResponse
     */
    'lead_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof SegmentSegmentResponse
     */
    'modified_at'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SegmentSegmentResponse
     */
    'perevod_kc'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SegmentSegmentResponse
     */
    'pipeline_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SegmentSegmentResponse
     */
    'project_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof SegmentSegmentResponse
     */
    'status_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SegmentSegmentResponse
     */
    'tag'?: string;
    /**
     * 
     * @type {string}
     * @memberof SegmentSegmentResponse
     */
    'utm_source'?: string;
}
/**
 * 
 * @export
 * @interface SegmentSegmentsListCount
 */
export interface SegmentSegmentsListCount {
    /**
     * 
     * @type {number}
     * @memberof SegmentSegmentsListCount
     */
    'count'?: number;
    /**
     * 
     * @type {Array<DomainSegmentWithLeadsCount>}
     * @memberof SegmentSegmentsListCount
     */
    'segments'?: Array<DomainSegmentWithLeadsCount>;
}
/**
 * 
 * @export
 * @interface SegmentToggleSegmentIntegrationActivity
 */
export interface SegmentToggleSegmentIntegrationActivity {
    /**
     * 
     * @type {boolean}
     * @memberof SegmentToggleSegmentIntegrationActivity
     */
    'active'?: boolean;
}
/**
 * 
 * @export
 * @interface UserCreateUserRequest
 */
export interface UserCreateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserRequest
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserRequest
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserRequest
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserRequest
     */
    'pass'?: string;
}
/**
 * 
 * @export
 * @interface UserUpdateUserRequest
 */
export interface UserUpdateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUserRequest
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUserRequest
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUserRequest
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUserRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUserRequest
     */
    'pass'?: string;
}
/**
 * 
 * @export
 * @interface UserUserResponse
 */
export interface UserUserResponse {
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'pass'?: string;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * авторизация пользователя.
         * @summary авторизация пользователя.
         * @param {AuthSignInRequest} user Данные для аутентификации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthSignInPost: async (user: AuthSignInRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('apiAuthSignInPost', 'user', user)
            const localVarPath = `/api/auth/sign-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * авторизация пользователя.
         * @summary авторизация пользователя.
         * @param {AuthSignInRequest} user Данные для аутентификации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthSignInPost(user: AuthSignInRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthSignInResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthSignInPost(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * авторизация пользователя.
         * @summary авторизация пользователя.
         * @param {AuthSignInRequest} user Данные для аутентификации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthSignInPost(user: AuthSignInRequest, options?: any): AxiosPromise<AuthSignInResponse> {
            return localVarFp.apiAuthSignInPost(user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * авторизация пользователя.
     * @summary авторизация пользователя.
     * @param {AuthSignInRequest} user Данные для аутентификации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthSignInPost(user: AuthSignInRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiAuthSignInPost(user, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DictionariesApi - axios parameter creator
 * @export
 */
export const DictionariesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * получить список городов.
         * @summary получить список городов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictionariesAmostatusGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dictionaries/amostatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить список городов.
         * @summary получить список городов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictionariesCityGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dictionaries/city`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DictionariesApi - functional programming interface
 * @export
 */
export const DictionariesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DictionariesApiAxiosParamCreator(configuration)
    return {
        /**
         * получить список городов.
         * @summary получить список городов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictionariesAmostatusGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadgenAmoStatusListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictionariesAmostatusGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить список городов.
         * @summary получить список городов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictionariesCityGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadgenCityListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictionariesCityGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DictionariesApi - factory interface
 * @export
 */
export const DictionariesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DictionariesApiFp(configuration)
    return {
        /**
         * получить список городов.
         * @summary получить список городов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictionariesAmostatusGet(options?: any): AxiosPromise<LeadgenAmoStatusListResponse> {
            return localVarFp.apiDictionariesAmostatusGet(options).then((request) => request(axios, basePath));
        },
        /**
         * получить список городов.
         * @summary получить список городов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictionariesCityGet(options?: any): AxiosPromise<LeadgenCityListResponse> {
            return localVarFp.apiDictionariesCityGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DictionariesApi - object-oriented interface
 * @export
 * @class DictionariesApi
 * @extends {BaseAPI}
 */
export class DictionariesApi extends BaseAPI {
    /**
     * получить список городов.
     * @summary получить список городов.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionariesApi
     */
    public apiDictionariesAmostatusGet(options?: AxiosRequestConfig) {
        return DictionariesApiFp(this.configuration).apiDictionariesAmostatusGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить список городов.
     * @summary получить список городов.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionariesApi
     */
    public apiDictionariesCityGet(options?: AxiosRequestConfig) {
        return DictionariesApiFp(this.configuration).apiDictionariesCityGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LeadApi - axios parameter creator
 * @export
 */
export const LeadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * получить список лидов за день.
         * @summary получить список лидов за день.
         * @param {string} date Дата
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadDateDateGet: async (date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('apiLeadDateDateGet', 'date', date)
            const localVarPath = `/api/lead/date/{date}`
                .replace(`{${"date"}}`, encodeURIComponent(String(date)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * выгрузить список лидов за день.
         * @summary выгрузить список лидов за день.
         * @param {string} date Дата
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadDownloadDateDateGet: async (date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('apiLeadDownloadDateDateGet', 'date', date)
            const localVarPath = `/api/lead/download/date/{date}`
                .replace(`{${"date"}}`, encodeURIComponent(String(date)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить список не распределенных номеров.
         * @summary получить список не распределенных номеров.
         * @param {string} date Дата сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadNotDefinedDateGet: async (date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('apiLeadNotDefinedDateGet', 'date', date)
            const localVarPath = `/api/lead/not_defined/{date}`
                .replace(`{${"date"}}`, encodeURIComponent(String(date)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Process the uploaded phone list file again
         * @summary Process the uploaded phone list file again
         * @param {File} file File to process
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadProcessPost: async (file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiLeadProcessPost', 'file', file)
            const localVarPath = `/api/lead/process`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить список лидов по сегменту за день.
         * @summary получить список лидов по сегменту за день.
         * @param {string} segmentId Идентификатор сегмента
         * @param {string} date Дата сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadSegmentIdDateGet: async (segmentId: string, date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'segmentId' is not null or undefined
            assertParamExists('apiLeadSegmentIdDateGet', 'segmentId', segmentId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('apiLeadSegmentIdDateGet', 'date', date)
            const localVarPath = `/api/lead/{segment_id}/{date}`
                .replace(`{${"segment_id"}}`, encodeURIComponent(String(segmentId)))
                .replace(`{${"date"}}`, encodeURIComponent(String(date)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeadApi - functional programming interface
 * @export
 */
export const LeadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LeadApiAxiosParamCreator(configuration)
    return {
        /**
         * получить список лидов за день.
         * @summary получить список лидов за день.
         * @param {string} date Дата
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeadDateDateGet(date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeadDateDateGet(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * выгрузить список лидов за день.
         * @summary выгрузить список лидов за день.
         * @param {string} date Дата
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeadDownloadDateDateGet(date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeadDownloadDateDateGet(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить список не распределенных номеров.
         * @summary получить список не распределенных номеров.
         * @param {string} date Дата сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeadNotDefinedDateGet(date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeadNotDefinedDateGet(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Process the uploaded phone list file again
         * @summary Process the uploaded phone list file again
         * @param {File} file File to process
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeadProcessPost(file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeadProcessPost(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить список лидов по сегменту за день.
         * @summary получить список лидов по сегменту за день.
         * @param {string} segmentId Идентификатор сегмента
         * @param {string} date Дата сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeadSegmentIdDateGet(segmentId: string, date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeadSegmentIdDateGet(segmentId, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LeadApi - factory interface
 * @export
 */
export const LeadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LeadApiFp(configuration)
    return {
        /**
         * получить список лидов за день.
         * @summary получить список лидов за день.
         * @param {string} date Дата
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadDateDateGet(date: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiLeadDateDateGet(date, options).then((request) => request(axios, basePath));
        },
        /**
         * выгрузить список лидов за день.
         * @summary выгрузить список лидов за день.
         * @param {string} date Дата
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadDownloadDateDateGet(date: string, options?: any): AxiosPromise<File> {
            return localVarFp.apiLeadDownloadDateDateGet(date, options).then((request) => request(axios, basePath));
        },
        /**
         * получить список не распределенных номеров.
         * @summary получить список не распределенных номеров.
         * @param {string} date Дата сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadNotDefinedDateGet(date: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiLeadNotDefinedDateGet(date, options).then((request) => request(axios, basePath));
        },
        /**
         * Process the uploaded phone list file again
         * @summary Process the uploaded phone list file again
         * @param {File} file File to process
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadProcessPost(file: File, options?: any): AxiosPromise<string> {
            return localVarFp.apiLeadProcessPost(file, options).then((request) => request(axios, basePath));
        },
        /**
         * получить список лидов по сегменту за день.
         * @summary получить список лидов по сегменту за день.
         * @param {string} segmentId Идентификатор сегмента
         * @param {string} date Дата сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadSegmentIdDateGet(segmentId: string, date: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiLeadSegmentIdDateGet(segmentId, date, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LeadApi - object-oriented interface
 * @export
 * @class LeadApi
 * @extends {BaseAPI}
 */
export class LeadApi extends BaseAPI {
    /**
     * получить список лидов за день.
     * @summary получить список лидов за день.
     * @param {string} date Дата
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadApi
     */
    public apiLeadDateDateGet(date: string, options?: AxiosRequestConfig) {
        return LeadApiFp(this.configuration).apiLeadDateDateGet(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * выгрузить список лидов за день.
     * @summary выгрузить список лидов за день.
     * @param {string} date Дата
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadApi
     */
    public apiLeadDownloadDateDateGet(date: string, options?: AxiosRequestConfig) {
        return LeadApiFp(this.configuration).apiLeadDownloadDateDateGet(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить список не распределенных номеров.
     * @summary получить список не распределенных номеров.
     * @param {string} date Дата сегмента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadApi
     */
    public apiLeadNotDefinedDateGet(date: string, options?: AxiosRequestConfig) {
        return LeadApiFp(this.configuration).apiLeadNotDefinedDateGet(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Process the uploaded phone list file again
     * @summary Process the uploaded phone list file again
     * @param {File} file File to process
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadApi
     */
    public apiLeadProcessPost(file: File, options?: AxiosRequestConfig) {
        return LeadApiFp(this.configuration).apiLeadProcessPost(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить список лидов по сегменту за день.
     * @summary получить список лидов по сегменту за день.
     * @param {string} segmentId Идентификатор сегмента
     * @param {string} date Дата сегмента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadApi
     */
    public apiLeadSegmentIdDateGet(segmentId: string, date: string, options?: AxiosRequestConfig) {
        return LeadApiFp(this.configuration).apiLeadSegmentIdDateGet(segmentId, date, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SegmentApi - axios parameter creator
 * @export
 */
export const SegmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * копирование сегмента.
         * @summary копирование сегмента.
         * @param {SegmentCopySegmentRequest} data Данные для копированая сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSegmentCopyPost: async (data: SegmentCopySegmentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiSegmentCopyPost', 'data', data)
            const localVarPath = `/api/segment/copy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить отфильтрованный список сегментов с количеством лидов за день.
         * @summary получить отфильтрованный список сегментов с количеством лидов за день.
         * @param {string} date Дата
         * @param {number} [offset] Начальный индекс
         * @param {number} [limit] Лимит
         * @param {string} [projectName] Поиск по названию
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSegmentDateDateGet: async (date: string, offset?: number, limit?: number, projectName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('apiSegmentDateDateGet', 'date', date)
            const localVarPath = `/api/segment/date/{date}`
                .replace(`{${"date"}}`, encodeURIComponent(String(date)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (projectName !== undefined) {
                localVarQueryParameter['projectName'] = projectName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * обновление активности интеграции.
         * @summary обновление активности интеграции.
         * @param {string} id Идентификатор сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSegmentIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSegmentIdDelete', 'id', id)
            const localVarPath = `/api/segment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * удалить домен из сегмента.
         * @summary удалить домен из сегмента.
         * @param {string} id Идентификатор сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSegmentIdDomainDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSegmentIdDomainDelete', 'id', id)
            const localVarPath = `/api/segment/{id}/domain`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * создание домена.
         * @summary создание домена.
         * @param {string} id Идентификатор сегмента
         * @param {SegmentCreateDomainRequest} data Данные для создания сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSegmentIdDomainPost: async (id: string, data: SegmentCreateDomainRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSegmentIdDomainPost', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiSegmentIdDomainPost', 'data', data)
            const localVarPath = `/api/segment/{id}/domain`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить список доменов сегмента.
         * @summary получить список доменов сегмента.
         * @param {string} id Идентификатор список доменов сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSegmentIdDomainsGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSegmentIdDomainsGet', 'id', id)
            const localVarPath = `/api/segment/{id}/domains`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить сегмент по id.
         * @summary получить сегмент по id.
         * @param {string} id Идентификатор сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSegmentIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSegmentIdGet', 'id', id)
            const localVarPath = `/api/segment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * удалить номер из сегмента.
         * @summary удалить номер из сегмента.
         * @param {string} id Идентификатор сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSegmentIdPhoneDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSegmentIdPhoneDelete', 'id', id)
            const localVarPath = `/api/segment/{id}/phone`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * создание номера.
         * @summary создание номера.
         * @param {string} id Идентификатор сегмента
         * @param {SegmentCreatePhoneRequest} data Данные для создания сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSegmentIdPhonePost: async (id: string, data: SegmentCreatePhoneRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSegmentIdPhonePost', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiSegmentIdPhonePost', 'data', data)
            const localVarPath = `/api/segment/{id}/phone`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить список номеров сегмента.
         * @summary получить список номеров сегмента.
         * @param {string} id Идентификатор список номеров сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSegmentIdPhonesGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSegmentIdPhonesGet', 'id', id)
            const localVarPath = `/api/segment/{id}/phones`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * обновление интеграции сегмента.
         * @summary обновление интеграции сегмента.
         * @param {string} id Идентификатор сегмента
         * @param {SegmentSegmentIntegrationParams} data Данные для создания сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSegmentIntegrationIdPut: async (id: string, data: SegmentSegmentIntegrationParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSegmentIntegrationIdPut', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiSegmentIntegrationIdPut', 'data', data)
            const localVarPath = `/api/segment/integration/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * обновление активности интеграции.
         * @summary обновление активности интеграции.
         * @param {string} id Идентификатор сегмента
         * @param {SegmentToggleSegmentIntegrationActivity} data Данные для создания сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSegmentIntegrationIdTogglePut: async (id: string, data: SegmentToggleSegmentIntegrationActivity, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSegmentIntegrationIdTogglePut', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiSegmentIntegrationIdTogglePut', 'data', data)
            const localVarPath = `/api/segment/integration/{id}/toggle`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * создание сегмента.
         * @summary создание сегмента.
         * @param {SegmentCreateSegemntRequest} data Данные для создания сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSegmentPost: async (data: SegmentCreateSegemntRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiSegmentPost', 'data', data)
            const localVarPath = `/api/segment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * прочитать отчет за даты.
         * @summary прочитать отчет за даты.
         * @param {string} from дата от
         * @param {string} to дата до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSegmentReportGet: async (from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('apiSegmentReportGet', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('apiSegmentReportGet', 'to', to)
            const localVarPath = `/api/segment/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить уникальные лиды за месяц.
         * @summary получить уникальные лиды за месяц.
         * @param {SegmentReadMonthReport} data Данные для формирования отчета
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSegmentReportPost: async (data: SegmentReadMonthReport, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiSegmentReportPost', 'data', data)
            const localVarPath = `/api/segment/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SegmentApi - functional programming interface
 * @export
 */
export const SegmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SegmentApiAxiosParamCreator(configuration)
    return {
        /**
         * копирование сегмента.
         * @summary копирование сегмента.
         * @param {SegmentCopySegmentRequest} data Данные для копированая сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSegmentCopyPost(data: SegmentCopySegmentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SegmentSegmentPhoneResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSegmentCopyPost(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить отфильтрованный список сегментов с количеством лидов за день.
         * @summary получить отфильтрованный список сегментов с количеством лидов за день.
         * @param {string} date Дата
         * @param {number} [offset] Начальный индекс
         * @param {number} [limit] Лимит
         * @param {string} [projectName] Поиск по названию
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSegmentDateDateGet(date: string, offset?: number, limit?: number, projectName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SegmentSegmentsListCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSegmentDateDateGet(date, offset, limit, projectName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * обновление активности интеграции.
         * @summary обновление активности интеграции.
         * @param {string} id Идентификатор сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSegmentIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSegmentIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * удалить домен из сегмента.
         * @summary удалить домен из сегмента.
         * @param {string} id Идентификатор сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSegmentIdDomainDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSegmentIdDomainDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * создание домена.
         * @summary создание домена.
         * @param {string} id Идентификатор сегмента
         * @param {SegmentCreateDomainRequest} data Данные для создания сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSegmentIdDomainPost(id: string, data: SegmentCreateDomainRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SegmentSegmentDomainResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSegmentIdDomainPost(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить список доменов сегмента.
         * @summary получить список доменов сегмента.
         * @param {string} id Идентификатор список доменов сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSegmentIdDomainsGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SegmentSegmentDomainResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSegmentIdDomainsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить сегмент по id.
         * @summary получить сегмент по id.
         * @param {string} id Идентификатор сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSegmentIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SegmentSegmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSegmentIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * удалить номер из сегмента.
         * @summary удалить номер из сегмента.
         * @param {string} id Идентификатор сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSegmentIdPhoneDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSegmentIdPhoneDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * создание номера.
         * @summary создание номера.
         * @param {string} id Идентификатор сегмента
         * @param {SegmentCreatePhoneRequest} data Данные для создания сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSegmentIdPhonePost(id: string, data: SegmentCreatePhoneRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SegmentSegmentPhoneResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSegmentIdPhonePost(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить список номеров сегмента.
         * @summary получить список номеров сегмента.
         * @param {string} id Идентификатор список номеров сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSegmentIdPhonesGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SegmentSegmentPhoneResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSegmentIdPhonesGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * обновление интеграции сегмента.
         * @summary обновление интеграции сегмента.
         * @param {string} id Идентификатор сегмента
         * @param {SegmentSegmentIntegrationParams} data Данные для создания сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSegmentIntegrationIdPut(id: string, data: SegmentSegmentIntegrationParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SegmentSegmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSegmentIntegrationIdPut(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * обновление активности интеграции.
         * @summary обновление активности интеграции.
         * @param {string} id Идентификатор сегмента
         * @param {SegmentToggleSegmentIntegrationActivity} data Данные для создания сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSegmentIntegrationIdTogglePut(id: string, data: SegmentToggleSegmentIntegrationActivity, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SegmentSegmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSegmentIntegrationIdTogglePut(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * создание сегмента.
         * @summary создание сегмента.
         * @param {SegmentCreateSegemntRequest} data Данные для создания сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSegmentPost(data: SegmentCreateSegemntRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SegmentSegmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSegmentPost(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * прочитать отчет за даты.
         * @summary прочитать отчет за даты.
         * @param {string} from дата от
         * @param {string} to дата до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSegmentReportGet(from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSegmentReportGet(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить уникальные лиды за месяц.
         * @summary получить уникальные лиды за месяц.
         * @param {SegmentReadMonthReport} data Данные для формирования отчета
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSegmentReportPost(data: SegmentReadMonthReport, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSegmentReportPost(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SegmentApi - factory interface
 * @export
 */
export const SegmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SegmentApiFp(configuration)
    return {
        /**
         * копирование сегмента.
         * @summary копирование сегмента.
         * @param {SegmentCopySegmentRequest} data Данные для копированая сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSegmentCopyPost(data: SegmentCopySegmentRequest, options?: any): AxiosPromise<SegmentSegmentPhoneResponse> {
            return localVarFp.apiSegmentCopyPost(data, options).then((request) => request(axios, basePath));
        },
        /**
         * получить отфильтрованный список сегментов с количеством лидов за день.
         * @summary получить отфильтрованный список сегментов с количеством лидов за день.
         * @param {string} date Дата
         * @param {number} [offset] Начальный индекс
         * @param {number} [limit] Лимит
         * @param {string} [projectName] Поиск по названию
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSegmentDateDateGet(date: string, offset?: number, limit?: number, projectName?: string, options?: any): AxiosPromise<SegmentSegmentsListCount> {
            return localVarFp.apiSegmentDateDateGet(date, offset, limit, projectName, options).then((request) => request(axios, basePath));
        },
        /**
         * обновление активности интеграции.
         * @summary обновление активности интеграции.
         * @param {string} id Идентификатор сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSegmentIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiSegmentIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * удалить домен из сегмента.
         * @summary удалить домен из сегмента.
         * @param {string} id Идентификатор сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSegmentIdDomainDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiSegmentIdDomainDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * создание домена.
         * @summary создание домена.
         * @param {string} id Идентификатор сегмента
         * @param {SegmentCreateDomainRequest} data Данные для создания сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSegmentIdDomainPost(id: string, data: SegmentCreateDomainRequest, options?: any): AxiosPromise<SegmentSegmentDomainResponse> {
            return localVarFp.apiSegmentIdDomainPost(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * получить список доменов сегмента.
         * @summary получить список доменов сегмента.
         * @param {string} id Идентификатор список доменов сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSegmentIdDomainsGet(id: string, options?: any): AxiosPromise<Array<SegmentSegmentDomainResponse>> {
            return localVarFp.apiSegmentIdDomainsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * получить сегмент по id.
         * @summary получить сегмент по id.
         * @param {string} id Идентификатор сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSegmentIdGet(id: string, options?: any): AxiosPromise<SegmentSegmentResponse> {
            return localVarFp.apiSegmentIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * удалить номер из сегмента.
         * @summary удалить номер из сегмента.
         * @param {string} id Идентификатор сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSegmentIdPhoneDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiSegmentIdPhoneDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * создание номера.
         * @summary создание номера.
         * @param {string} id Идентификатор сегмента
         * @param {SegmentCreatePhoneRequest} data Данные для создания сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSegmentIdPhonePost(id: string, data: SegmentCreatePhoneRequest, options?: any): AxiosPromise<SegmentSegmentPhoneResponse> {
            return localVarFp.apiSegmentIdPhonePost(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * получить список номеров сегмента.
         * @summary получить список номеров сегмента.
         * @param {string} id Идентификатор список номеров сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSegmentIdPhonesGet(id: string, options?: any): AxiosPromise<Array<SegmentSegmentPhoneResponse>> {
            return localVarFp.apiSegmentIdPhonesGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * обновление интеграции сегмента.
         * @summary обновление интеграции сегмента.
         * @param {string} id Идентификатор сегмента
         * @param {SegmentSegmentIntegrationParams} data Данные для создания сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSegmentIntegrationIdPut(id: string, data: SegmentSegmentIntegrationParams, options?: any): AxiosPromise<SegmentSegmentResponse> {
            return localVarFp.apiSegmentIntegrationIdPut(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * обновление активности интеграции.
         * @summary обновление активности интеграции.
         * @param {string} id Идентификатор сегмента
         * @param {SegmentToggleSegmentIntegrationActivity} data Данные для создания сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSegmentIntegrationIdTogglePut(id: string, data: SegmentToggleSegmentIntegrationActivity, options?: any): AxiosPromise<SegmentSegmentResponse> {
            return localVarFp.apiSegmentIntegrationIdTogglePut(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * создание сегмента.
         * @summary создание сегмента.
         * @param {SegmentCreateSegemntRequest} data Данные для создания сегмента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSegmentPost(data: SegmentCreateSegemntRequest, options?: any): AxiosPromise<SegmentSegmentResponse> {
            return localVarFp.apiSegmentPost(data, options).then((request) => request(axios, basePath));
        },
        /**
         * прочитать отчет за даты.
         * @summary прочитать отчет за даты.
         * @param {string} from дата от
         * @param {string} to дата до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSegmentReportGet(from: string, to: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiSegmentReportGet(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * получить уникальные лиды за месяц.
         * @summary получить уникальные лиды за месяц.
         * @param {SegmentReadMonthReport} data Данные для формирования отчета
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSegmentReportPost(data: SegmentReadMonthReport, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiSegmentReportPost(data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SegmentApi - object-oriented interface
 * @export
 * @class SegmentApi
 * @extends {BaseAPI}
 */
export class SegmentApi extends BaseAPI {
    /**
     * копирование сегмента.
     * @summary копирование сегмента.
     * @param {SegmentCopySegmentRequest} data Данные для копированая сегмента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentApi
     */
    public apiSegmentCopyPost(data: SegmentCopySegmentRequest, options?: AxiosRequestConfig) {
        return SegmentApiFp(this.configuration).apiSegmentCopyPost(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить отфильтрованный список сегментов с количеством лидов за день.
     * @summary получить отфильтрованный список сегментов с количеством лидов за день.
     * @param {string} date Дата
     * @param {number} [offset] Начальный индекс
     * @param {number} [limit] Лимит
     * @param {string} [projectName] Поиск по названию
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentApi
     */
    public apiSegmentDateDateGet(date: string, offset?: number, limit?: number, projectName?: string, options?: AxiosRequestConfig) {
        return SegmentApiFp(this.configuration).apiSegmentDateDateGet(date, offset, limit, projectName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * обновление активности интеграции.
     * @summary обновление активности интеграции.
     * @param {string} id Идентификатор сегмента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentApi
     */
    public apiSegmentIdDelete(id: string, options?: AxiosRequestConfig) {
        return SegmentApiFp(this.configuration).apiSegmentIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * удалить домен из сегмента.
     * @summary удалить домен из сегмента.
     * @param {string} id Идентификатор сегмента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentApi
     */
    public apiSegmentIdDomainDelete(id: string, options?: AxiosRequestConfig) {
        return SegmentApiFp(this.configuration).apiSegmentIdDomainDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * создание домена.
     * @summary создание домена.
     * @param {string} id Идентификатор сегмента
     * @param {SegmentCreateDomainRequest} data Данные для создания сегмента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentApi
     */
    public apiSegmentIdDomainPost(id: string, data: SegmentCreateDomainRequest, options?: AxiosRequestConfig) {
        return SegmentApiFp(this.configuration).apiSegmentIdDomainPost(id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить список доменов сегмента.
     * @summary получить список доменов сегмента.
     * @param {string} id Идентификатор список доменов сегмента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentApi
     */
    public apiSegmentIdDomainsGet(id: string, options?: AxiosRequestConfig) {
        return SegmentApiFp(this.configuration).apiSegmentIdDomainsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить сегмент по id.
     * @summary получить сегмент по id.
     * @param {string} id Идентификатор сегмента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentApi
     */
    public apiSegmentIdGet(id: string, options?: AxiosRequestConfig) {
        return SegmentApiFp(this.configuration).apiSegmentIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * удалить номер из сегмента.
     * @summary удалить номер из сегмента.
     * @param {string} id Идентификатор сегмента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentApi
     */
    public apiSegmentIdPhoneDelete(id: string, options?: AxiosRequestConfig) {
        return SegmentApiFp(this.configuration).apiSegmentIdPhoneDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * создание номера.
     * @summary создание номера.
     * @param {string} id Идентификатор сегмента
     * @param {SegmentCreatePhoneRequest} data Данные для создания сегмента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentApi
     */
    public apiSegmentIdPhonePost(id: string, data: SegmentCreatePhoneRequest, options?: AxiosRequestConfig) {
        return SegmentApiFp(this.configuration).apiSegmentIdPhonePost(id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить список номеров сегмента.
     * @summary получить список номеров сегмента.
     * @param {string} id Идентификатор список номеров сегмента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentApi
     */
    public apiSegmentIdPhonesGet(id: string, options?: AxiosRequestConfig) {
        return SegmentApiFp(this.configuration).apiSegmentIdPhonesGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * обновление интеграции сегмента.
     * @summary обновление интеграции сегмента.
     * @param {string} id Идентификатор сегмента
     * @param {SegmentSegmentIntegrationParams} data Данные для создания сегмента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentApi
     */
    public apiSegmentIntegrationIdPut(id: string, data: SegmentSegmentIntegrationParams, options?: AxiosRequestConfig) {
        return SegmentApiFp(this.configuration).apiSegmentIntegrationIdPut(id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * обновление активности интеграции.
     * @summary обновление активности интеграции.
     * @param {string} id Идентификатор сегмента
     * @param {SegmentToggleSegmentIntegrationActivity} data Данные для создания сегмента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentApi
     */
    public apiSegmentIntegrationIdTogglePut(id: string, data: SegmentToggleSegmentIntegrationActivity, options?: AxiosRequestConfig) {
        return SegmentApiFp(this.configuration).apiSegmentIntegrationIdTogglePut(id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * создание сегмента.
     * @summary создание сегмента.
     * @param {SegmentCreateSegemntRequest} data Данные для создания сегмента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentApi
     */
    public apiSegmentPost(data: SegmentCreateSegemntRequest, options?: AxiosRequestConfig) {
        return SegmentApiFp(this.configuration).apiSegmentPost(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * прочитать отчет за даты.
     * @summary прочитать отчет за даты.
     * @param {string} from дата от
     * @param {string} to дата до
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentApi
     */
    public apiSegmentReportGet(from: string, to: string, options?: AxiosRequestConfig) {
        return SegmentApiFp(this.configuration).apiSegmentReportGet(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить уникальные лиды за месяц.
     * @summary получить уникальные лиды за месяц.
     * @param {SegmentReadMonthReport} data Данные для формирования отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentApi
     */
    public apiSegmentReportPost(data: SegmentReadMonthReport, options?: AxiosRequestConfig) {
        return SegmentApiFp(this.configuration).apiSegmentReportPost(data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * удалить пользователя
         * @summary удалить пользователя
         * @param {string} id Идентификатор пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserIdDelete', 'id', id)
            const localVarPath = `/api/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * обновление пользователя.
         * @summary обновление пользователя.
         * @param {string} id Идентификатор пользователя
         * @param {UserUpdateUserRequest} user Данные для обновления пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdPut: async (id: string, user: UserUpdateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserIdPut', 'id', id)
            // verify required parameter 'user' is not null or undefined
            assertParamExists('apiUserIdPut', 'user', user)
            const localVarPath = `/api/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * создание пользователя.
         * @summary создание пользователя.
         * @param {UserCreateUserRequest} user Данные пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserPost: async (user: UserCreateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('apiUserPost', 'user', user)
            const localVarPath = `/api/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * удалить пользователя
         * @summary удалить пользователя
         * @param {string} id Идентификатор пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * обновление пользователя.
         * @summary обновление пользователя.
         * @param {string} id Идентификатор пользователя
         * @param {UserUpdateUserRequest} user Данные для обновления пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserIdPut(id: string, user: UserUpdateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserIdPut(id, user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * создание пользователя.
         * @summary создание пользователя.
         * @param {UserCreateUserRequest} user Данные пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserPost(user: UserCreateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserPost(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * удалить пользователя
         * @summary удалить пользователя
         * @param {string} id Идентификатор пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdDelete(id: string, options?: any): AxiosPromise<UserUserResponse> {
            return localVarFp.apiUserIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * обновление пользователя.
         * @summary обновление пользователя.
         * @param {string} id Идентификатор пользователя
         * @param {UserUpdateUserRequest} user Данные для обновления пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdPut(id: string, user: UserUpdateUserRequest, options?: any): AxiosPromise<UserUserResponse> {
            return localVarFp.apiUserIdPut(id, user, options).then((request) => request(axios, basePath));
        },
        /**
         * создание пользователя.
         * @summary создание пользователя.
         * @param {UserCreateUserRequest} user Данные пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserPost(user: UserCreateUserRequest, options?: any): AxiosPromise<UserUserResponse> {
            return localVarFp.apiUserPost(user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * удалить пользователя
     * @summary удалить пользователя
     * @param {string} id Идентификатор пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUserIdDelete(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUserIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * обновление пользователя.
     * @summary обновление пользователя.
     * @param {string} id Идентификатор пользователя
     * @param {UserUpdateUserRequest} user Данные для обновления пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUserIdPut(id: string, user: UserUpdateUserRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUserIdPut(id, user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * создание пользователя.
     * @summary создание пользователя.
     * @param {UserCreateUserRequest} user Данные пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUserPost(user: UserCreateUserRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUserPost(user, options).then((request) => request(this.axios, this.basePath));
    }
}


