import axios from "axios";
import { Segment } from "../../../domain/segment/segment";
import { Configuration, DomainLeadType, DomainSegmentWithLeadsCount, SegmentApi, SegmentSegmentDomainResponse, SegmentSegmentPhoneResponse, SegmentSegmentResponse } from "../generated";
import { SegmentInterface } from "../../../useCase/segment/adapters/interface";
import { SegmentWithLeadsCount } from "../../../domain/segment/segment_with_leads_count";
import { SegmentDomain } from "../../../domain/segment/segment_domain";
import { SegmentPhone } from "../../../domain/segment/segment_phone";

export class SegmentsRepository implements SegmentInterface {
    private service: SegmentApi;

    constructor() {
        this.service = new SegmentApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async SegmentsListWithCountOfLeadsByDate(date: Date, projectName: string, offset: number, limit: number): Promise<{count: number, segments: SegmentWithLeadsCount[]} | Error> {
        try {
            let response = await this.service.apiSegmentDateDateGet(date.toISOString(), offset, limit, projectName, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let segments: SegmentWithLeadsCount[] = []
                response.data?.segments!.forEach((segment) => {
                    let item = this.toDomainSegmentWithLeadsCount(segment)
                   
                    segments.push(item)
                })
                return {count: response.data.count!, segments: segments}
            } else {
                if (axios.isAxiosError(response)) {
                    return Error(response?.message)
                }
                return Error("something went wrong")
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error(error)
        }
    }

    async CreateSegment(segmentName: string): Promise<Segment | Error> {
        try {
            let response = await this.service.apiSegmentPost({name: segmentName},   { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return this.toDomainSegment(response.data)
            } else {
                if (axios.isAxiosError(response)) {
                    return Error(response?.message)
                }
                return Error("something went wrong")
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error(error)
        }
    }

    async CopySegment(id: UniqueId, segmentName: string): Promise<Segment | Error> {
        try {
            let response = await this.service.apiSegmentCopyPost({id: id, name: segmentName},   { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return this.toDomainSegment(response.data)
            } else {
                if (axios.isAxiosError(response)) {
                    return Error(response?.message)
                }
                return Error("something went wrong")
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error(error)
        }
    }

    async ReadSegmentById(segmentId: string): Promise<Segment | Error> {
        try {
            let response = await this.service.apiSegmentIdGet(segmentId,   { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return this.toDomainSegment(response.data)
            } else {
                if (axios.isAxiosError(response)) {
                    return Error(response?.message)
                }
                return Error("something went wrong")
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error(error)
        }
    }

    async UpdateSegmentIntegration(id: string, leadType: string, pipelineId: number, statusId: number, cityId: number, entityId: number, tag: string, utmSource: string, perevodKc: boolean, ): Promise<Segment | Error> {
        try {
            let ltype: DomainLeadType
            switch (leadType) {
                case String(DomainLeadType.Avto):
                    ltype = DomainLeadType.Avto
                    break;
                case String(DomainLeadType.Jk):
                    ltype = DomainLeadType.Jk
                    break;
                default:
                    ltype = DomainLeadType.Default;
                    break;
            }
            let response = await this.service.apiSegmentIntegrationIdPut(id, {lead_type: ltype, pipeline_id: pipelineId, status_id: statusId, city_id: cityId,  entity_id: entityId, utm_source: utmSource, tag:tag, perevod_kc: perevodKc },   { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return this.toDomainSegment(response.data)
            } else {
                if (axios.isAxiosError(response)) {
                    return Error(response?.message)
                }
                return Error("something went wrong")
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error(error)
        }
    }

    async ToggleSegmentIntegrationActivity(id: string, active: boolean ): Promise<Segment | Error> {
        try {
            let response = await this.service.apiSegmentIntegrationIdTogglePut(id, {active: active},   { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return this.toDomainSegment(response.data)
            } else {
                if (axios.isAxiosError(response)) {
                    return Error(response?.message)
                }
                return Error("something went wrong")
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.error
            }

            return Error(error)
        }
    }

    async DeleteSegment(id: UniqueId): Promise<string | Error> {
        try {
            let response = await this.service.apiSegmentIdDelete(id, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return "success"
            } else {
                if (axios.isAxiosError(response)) {
                    return Error(response?.message)
                }
                return Error("something went wrong")
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error(error)
        }
    }

    toDomainSegment(segment: SegmentSegmentResponse): Segment {
        return new Segment(segment.id!, segment.project_name!, new Date(segment.created_at!), new Date(segment.modified_at!), segment.is_integration_active!, segment.lead_type!, segment.pipeline_id!, segment.status_id!, segment.city_id!, segment.entity_id!, segment.tag!, segment.utm_source!, segment.perevod_kc!)
    }

    toDomainSegmentWithLeadsCount(segment: DomainSegmentWithLeadsCount): SegmentWithLeadsCount {
        switch (typeof segment.last_processed_lead_date) {
            case "string":
                let date = <string> segment.last_processed_lead_date!;

                return new SegmentWithLeadsCount(segment.id!, segment.name!, segment.is_integration_active!, segment.leads_count!, new Date(segment.created_at!), new Date(date))
            default:
                return new SegmentWithLeadsCount(segment.id!, segment.name!, segment.is_integration_active!, segment.leads_count!, new Date(segment.created_at!))

        }
       
        
    }

    async SegmentDomainsList(segmentId: UniqueId): Promise<SegmentDomain[] | Error> {
        try {
            let response = await this.service.apiSegmentIdDomainsGet(segmentId, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let domains: SegmentDomain[] = []
                response.data!.forEach((domain) => {
                    let item = this.toDomainOfSegment(domain)
                   
                    domains.push(item)
                })
                return domains
            } else {
                if (axios.isAxiosError(response)) {
                    return Error(response?.message)
                }
                return Error("something went wrong")
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error(error)
        }
    }

    async CreateDomainForSegment(segmentId: UniqueId, domain: string): Promise<SegmentDomain | Error> {
        try {
            let response = await this.service.apiSegmentIdDomainPost(segmentId, {domain: domain},   { headers: { "Authorization": localStorage.getItem("token") } })
            console.log(response)
            if (response.status === 200) {
                return this.toDomainOfSegment(response.data)
            } else {
                console.log(response)
                if (axios.isAxiosError(response)) {
                    return Error(response?.message)
                }
                return Error("something went wrong")
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.error
            }

            return Error(error)
        }
    } 

    async DeleteDomainSegment(id: UniqueId): Promise<string | Error> {
        try {
            let response = await this.service.apiSegmentIdDomainDelete(id, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return "success"
            } else {
                if (axios.isAxiosError(response)) {
                    return Error(response?.message)
                }
                return Error("something went wrong")
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error(error)
        }
    }

    toDomainOfSegment(segment: SegmentSegmentDomainResponse): SegmentDomain {
        return new SegmentDomain(segment.id!, segment.segment_id!, segment.domain!, new Date(segment.created_at!))
    }

    async SegmentPhonesList(segmentId: UniqueId): Promise<SegmentPhone[] | Error> {
        try {
            let response = await this.service.apiSegmentIdPhonesGet(segmentId, { headers: { "Authorization": localStorage.getItem("token") } })
    
            if (response.status === 200) {
                let phones: SegmentPhone[] = []
                response.data!.forEach((phone) => {
                    let item = this.toPhoneOfSegment(phone)
                   
                    phones.push(item)
                })
                return phones
            } else {
                if (axios.isAxiosError(response)) {
                    return Error(response?.message)
                }
                return Error("something went wrong")
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
    
            return Error(error)
        }
    }
    
    async CreatePhoneForSegment(segmentId: UniqueId, phone: string): Promise<SegmentPhone | Error> {
        try {
            let response = await this.service.apiSegmentIdPhonePost(segmentId, {phone: phone},   { headers: { "Authorization": localStorage.getItem("token") } })
    
            if (response.status === 200) {
                return this.toPhoneOfSegment(response.data)
            } else {
                if (axios.isAxiosError(response)) {
                    return Error(response?.message)
                }
                return Error("something went wrong")
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.error
            }
    
            return Error(error)
        }
    } 
    
    async DeletePhoneSegment(id: UniqueId): Promise<string | Error> {
        try {
            let response = await this.service.apiSegmentIdPhoneDelete(id, { headers: { "Authorization": localStorage.getItem("token") } })
    
            if (response.status === 200) {
                return "success"
            } else {
                if (axios.isAxiosError(response)) {
                    return Error(response?.message)
                }
                return Error("something went wrong")
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
    
            return Error(error)
        }
    }

    async ReadReport(month: number, year: number): Promise<string[] | Error> {
        try {
            let response = await this.service.apiSegmentReportPost({year: year, month: month }, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
               
                return response.data!
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }
    
    toPhoneOfSegment(segment: SegmentSegmentPhoneResponse): SegmentPhone {
        return new SegmentPhone(segment.id!, segment.segment_id!, segment.phone!, new Date(segment.created_at!))
    }
    
}